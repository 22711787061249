import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import PreviewCompatibleImage from './PreviewCompatibleImage';

const ControlledCarousel = ({
  items, activeIndex, selectHandler, carouselItemHeight, showCTA, showPreviews
}) => (
  <div>
    <Carousel activeIndex={ activeIndex } onSelect={ selectHandler } indicators={ false } interval={ 10000 }>
      {items.map((item, index) => (
        <Carousel.Item key={ `${index}-carouselItem` } style={{ height: carouselItemHeight, overflow: 'hidden' }}>
          <PreviewCompatibleImage
            imageInfo={{ image: item.image }}
            imageClasses="w-100 h-100"
            imageStyle={ showCTA ? { filter: 'brightness(60%)' } : {} }
          />
          {showCTA && (
            <Carousel.Caption style={{
              position: 'absolute', bottom: '20%', left: '15%', textAlign: 'left', maxWidth: '300px'
            }}
            >
              <h3>{item.title}</h3>
              <p>{item.shortDescription}</p>
              <Link to={ item.route } className="btn btn-primary">
                {item.actionTitle}
              </Link>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
    {showPreviews && (
      <div className="d-none d-lg-block mt-lg-3">
        <ul
          className="d-flex justify-content-between list-unstyled"
        >
          { items.map((item, index) => (
            // event handlers not working with Img from 'gatsby-image'
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <li
              key={ `${index}-carouselThumbnail` }
              style={{
                width: '150px', height: '100px', opacity: activeIndex === index ? '0.9' : '0.6'
              }}
              onClick={ () => selectHandler(index) }
            >
              <div className="position-relative h-100 w-100">
                <PreviewCompatibleImage
                  imageInfo={{ image: item.image }}
                  imageClasses="w-100 h-100"
                  imageStyle={{ border: '1px solid white' }}
                />
                { item.bannerText
                && (
                  <div
                    className="position-absolute bg-white w-100 d-flex justify-content-center align-items-center"
                    style={{
                      top: '50%', transform: 'translateY(-50%)', opacity: '0.7', height: '50px'
                    }}
                  >
                    <p className="text-center text-dark text-break my-0" style={{ lineHeight: '1' }}>{ item.bannerText }</p>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

ControlledCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    bannerText: PropTypes.string,
    shortDescription: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
    actionTitle: PropTypes.string,
    route: PropTypes.string
  })).isRequired,
  activeIndex: PropTypes.number.isRequired,
  selectHandler: PropTypes.func.isRequired,
  carouselItemHeight: PropTypes.string,
  showCTA: PropTypes.bool,
  showPreviews: PropTypes.bool
};

ControlledCarousel.defaultProps = {
  carouselItemHeight: '600px',
  showCTA: false,
  showPreviews: true
};

export default ControlledCarousel;